<template>
  <div class="main">
    <base-dialog :show="!!error" title="Błąd!" @close="handleError">
    {{ "Błąd podczas przesyłania formularza"  }}<br>
      {{ "Odpowiedź z serwera: " + error }}
    </base-dialog>
    <base-dialog :show="!!response" title="Udało sie!" @close="handleError">
      Dziekujemy za kontakt, odpowiedmy najszybciej jak to możliwe. 
    </base-dialog>
    <base-dialog :show="!!validadionError" title="Błąd w formularzu" @close="handleError">
      {{ validadionError }}
    </base-dialog>
    <app-header ></app-header>
    <div class="photo-container">
      <app-menu v-if="!isMobile" @scrollToSection="scrollToSection"></app-menu>
      <app-hero :isMobile="this.isMobile" @scrollToSection="scrollToSection"></app-hero>
    </div>
    <what-we-are-doin @scrollToSection="scrollToSection" ref="what"></what-we-are-doin>
    <are-you-interested-in-mesurment
      @scrollToSection="scrollToSection"
    ></are-you-interested-in-mesurment>
    <our-team ref="teamSection"></our-team>
    <app-benefits ref="benefits"></app-benefits>
    <contact-form ref="contact" :isMobile="this.isMobile" @response="handleResponse" @validationError="handleValidationError" @error="handleResponseError"></contact-form>
    <app-footer ref="footer" @scrollToSection="scrollToSection"></app-footer>
    <mobile-app-menu class="mobile-menu" :style="menuStyle" @scrollToSection="scrollToSection" v-if="isMobile"></mobile-app-menu>
    
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppMenu from "./components/AppMenu.vue";
import MobileAppMenu from "./components/MobileAppMenu.vue";
import AppHero from "./components/AppHero.vue";
import WhatWeAreDoin from "./components/WhatWeAreDoing.vue";
import AreYouInterestedInMesurment from "./components/AreYouInterestedInMesurment.vue";
import OurTeam from "./components/OurTeam.vue";
import AppBenefits from "./components/AppBenefits.vue";
import AppFooter from "./components/AppFooter.vue";
import ContactForm from "./components/ContactForm.vue";
import BaseDialog from "./components/BaseDialog.vue"

export default {
  components: {
    AppHeader,
    AppMenu,
    MobileAppMenu,
    AppHero,
    WhatWeAreDoin,
    AreYouInterestedInMesurment,
    OurTeam,
    AppBenefits,
    AppFooter,
    ContactForm,
    BaseDialog
  },
  data() {
    return{
      isMobile: false,
      menuVisible: false,  
      lastScrollTop: 0,
      error: null,
      validadionError: null,
      response: null    
    }
    
  },
  
  methods: {
    handleError() {
      this.error = null;
      this.response = null; 
      this.validadionError = null; 
    },
    handleResponseError(error) {
      this.error = error;
    },
    handleValidationError(validationError) {
      this.validadionError = validationError;
    },
    handleResponse(response) {
      this.response = response;
    },
    scrollToSection(dataFromChild) {
      const childComponent = this.$refs[dataFromChild];
      childComponent.$el.scrollIntoView({ behavior: "smooth" });
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    handleScroll() {
      const st = window.pageYYOffset || document.documentElement.scrollTop;

      
      if (st < this.lastScrollTop && !this.menuVisible) {
        this.menuVisible = true;
      } 
      
      else if (st > this.lastScrollTop && this.menuVisible) {
        this.menuVisible = false;
      }
      this.lastScrollTop = st;
    },
  },
  computed: {
    menuStyle() {
      return {
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        transform: this.menuVisible ? "translateY(0)" : "translateY(100%)",
        transition: "transform 0.3s ease",
      };
    },
  },
  created() {
    this.checkIfMobile(); 
    window.addEventListener('resize', this.checkIfMobile); 
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.addEventListener('resize', this.checkIfMobile); 
    window.removeEventListener("scroll", this.handleScroll);
  },
 
    
 
};
</script>

<style lang="scss">
@import "./main.scss";
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: Arial, sens-serif;
  font-weight: 400;
  line-height: 1.75;
}

.photo-container {
  background-image: url("./assets/herbLower.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center bottom;
 
  padding-bottom: 2rem;
  margin: 0px;
}

.main {
  padding: 0px;
  margin: 0px;
}

.mobile-menu{
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}
</style>
