<template>
  <div class="header">
    <img src="./../assets/up.jpg" />
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      logo: require("@/assets/Leafmatic_logo-01.svg"),
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";
.header {
  display: flex;
  justify-content: center;
}

img {
  max-width: 100%;
}
</style>
