import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios';
import VueGtag from "vue-gtag";



axios.defaults.baseURL = 'http://localhost/index.php'; 

const isProduction = process.env.NODE_ENV === 'production';



const globMet = {
    googleEvent: function(action, category, label, value) {
      if(!isProduction) {
        console.log('wersja developerska: ')
        console.log("action: " + action)
        console.log("event_category: " + category)
        console.log("event_label: " + label)
        console.log("value: " + value)
   
      }
      else{
        app.config.globalProperties.$gtag.event(action, {
            'event_category': category,
            'event_label': label,
            'value': value
          })
      }
    },
  };

const app = createApp(App);
app.config.globalProperties.$globMet = globMet;

if(isProduction){
    app.use(store).use(router).use(VueGtag, {config: { id: "G-74D92967Q9" }}).mount("#app");
}else {
    app.use(store).use(router).mount("#app");
}

